<template>
  <component :is="pageTemplate" :key="key"></component>
</template>
<style scoped lang="scss"></style>
<script>
import split from "lodash/split";

import categoryMixins from "~/mixins/category";
import deliveryReactive from "~/mixins/deliveryReactive";

export default {
  name: "Category",
  mixins: [categoryMixins, deliveryReactive],
  data() {
    return {
      key: 1
    };
  },
  computed: {
    pageTemplate() {
      let template = "category-med";
      if (this.category.navigationTypeId == 1) {
        template = "category-leaflet";
      } else if (this.category.template) {
        template = this.category.template;
      }
      return () => import(`@/components/category/${template}.vue`);
    }
  },
  methods: {
    async reload() {
      let slug = split(this.category.slug, "@")[0];
      await this.resetFilters(slug);
      await this.setCategory({ slug: slug, force: true });
      this.key += 1;
    }
  },
  async created() {
    await this.resetFilters(this.category.slug);
  },
  watch: {
    async $route(to) {
      let slug = to.params.pathMatch;
      await this.resetFilters(slug);
      await this.setCategory({ slug });
    }
  }
};
</script>
